/*
 * @Author: zhangxueke
 * @Date: 2021-11-08 09:45:59
 * @LastEditTime: 2022-01-12 17:29:04
 * @LastEditors: miaojiawei
 * @Description:
 */
import React, { useCallback } from 'react';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, message, Spin } from 'antd';
import { history, useModel } from 'umi';
import { stringify } from 'querystring';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { outLogin } from '@/services/api/user';
import type { MenuInfo } from 'rc-menu/lib/interface';
import IconFont from '../IconFont';
import _ from 'lodash';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = async () => {
  /* 请求登出api */
  try {
    const auth: any = localStorage.getItem('auth');
    const { access_token, expires_in, refresh_token } = JSON.parse(auth);
    const res = await outLogin({ access_token });
    if (res.status == 200) {
      message.success('退出成功');
    }
  } catch (error) {}

  const { query = {}, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    setTimeout(() => {
      history.replace({
        pathname: '/user/login',
        search: stringify({
          redirect: pathname,
        }),
      });
    }, 1000);
  }
  localStorage.removeItem('auth');
  localStorage.removeItem('userInfo');
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout' && initialState) {
        setInitialState({ ...initialState, currentUser: undefined });
        loginOut();
        history.push(`/account/${key}`);
        return;
      }
      if (key === 'userInfo') {
        try {
          const user: any = localStorage.getItem('userInfo');
          const userInfo: any = JSON.parse(user);
          const { menuList } = userInfo;
          const { subMenus } = menuList[0];
          let data = _.cloneDeep(subMenus);
          data.map((item: any) => {
            item.subMenus &&
              item.subMenus.map((ii: any) => {
                if (ii.path == '/system/menus') {
                  history.push('/system/menus');
                }
              });
          });
        } catch {}
      }
    },
    [initialState, setInitialState],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.name) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="userInfo" className={`${styles.userNameItem}`}>
        <div className={`${styles.userName}`}>{currentUser.name}</div>
      </Menu.Item>

      {/* <Menu.Item key="center">
        <IconFont type="icon-gerenziliao" />
        个人中心
      </Menu.Item> */}

      <Menu.Item key="logout">
        <IconFont type="icon-yonghuzhuxiao" />
        退出登录
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span
        className={`${styles.action} ${styles.account}`}
        style={{ marginLeft: '30px', width: '46px', height: '46px', borderRadius: '32px' }}
      >
        <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" />
        {/* <span className={`${styles.name} anticon`}>{currentUser.name}</span> */}
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
