/*
 * @Author: yixin xu
 * @Date: 2021-12-08 14:37:04
 * @LastEditTime: 2021-12-08 14:44:46
 * @LastEditors: yixin xu
 * @Description:
 */

import { useCallback, useState } from 'react';

export default () => {
  const [isOver, setOver] = useState(false);

  const changeOver = useCallback((s) => {
    setOver(() => s);
  }, []);

  return { isOver, changeOver };
};
