/*
 * @Author: miaojiawei
 * @Date: 2022-01-07 09:19:20
 * @LastEditTime: 2022-01-11 11:50:05
 * @LastEditors: miaojiawei
 * @Description:
 */
import { request } from 'umi';

export const userLogin = (data: any) => {
  return request(`/api/api-auth/oauth/telematics/token`, {
    method: 'POST',
    data,
  });
};

export const outLogin = (params: any) => {
  return request(`/api/api-auth/oauth/remove/token`, {
    method: 'POST',
    params,
  });
};
