/*
 * @Author: zhangxueke
 * @Date: 2021-11-25 09:26:24
 * @LastEditTime: 2021-11-25 14:53:56
 * @LastEditors: zhangxueke
 * @Description:
 */

import { useState } from 'react';

export default () => {
  const [zyljghcsOk, setZyljghcsOk] = useState(false);
  const [clcsOk, setClcsOk] = useState(false);
  const [zyzdcsOk, setZyzdcsOk] = useState(false);

  return {
    zyljghcsOk,
    setZyljghcsOk,
    clcsOk,
    setClcsOk,
    zyzdcsOk,
    setZyzdcsOk,
  };
};
