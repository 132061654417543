export default {
  // models命名空间，需全局唯一
  namespace: 'paraSetVal',
  // models存储的数据store
  state: {
    disable: false, // 禁用表单
    dataList: [], // 当前操作的叶子节点数组
    setFormVal: [], // 数据回显
    getFormVal: [], // 获取参数
  },
  // 更新store，用新数据合并state的旧数据
  reducers: {
    save(state, { payload }) {
      console.log(payload, 'payload');
      return { ...state, ...payload };
    },
    setVal(state, { payload }) {
      console.log(payload, 'payload');
      return { ...state, getFormVal: payload.getFormVal };
    },
  },
};
