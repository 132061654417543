/*
 * @Author: your name
 * @Date: 2021-09-22 08:58:56
 * @LastEditTime: 2021-10-26 15:04:55
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \iov-web\src\access.ts
 */
/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: any) {
  const { isAdmin, buttonRole = [] } = initialState;
  return {
    testRouteFilter: true,
    adminRouteFilter: () => isAdmin, // 只有管理员可访问
    normalRouteFilter: (route: any) => {
      // return !hasRoutes.includes(route.path);
      return true;
    }, // initialState 中包含了的路由才有权限访问
    canShow: (uniqueId?: string | number | null | undefined) => {
      //console.log(buttonRole.includes(uniqueId));
      return true;
    },
  };
}
